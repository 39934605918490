import React, {
  Reducer,
  useEffect,
  useReducer
} from 'react';
import {
  Box,
  IconButton,
  Modal
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import { keyframes, Keyframes } from '@emotion/react';
import { useDispatch, useSelector } from 'src/hooks';
import {
  DivMenuItem,
  isDivMenuItem,
  isSubMenuItem,
  isTopMenuItem,
  MENU_ITEM_TYPE_TOP,
  MenuItem,
  MenuItemChildren,
  SubMenuItem,
  SubMenuItems,
  TopMenuItem,
  TopMenuItems
} from './menu';
import OncoreFlexMenuDivider from './OncoreFlexMenuDivider';
import OncoreFlexMenuFill from './OncoreFlexMenuFill';
import OncoreFlexMenuMobileItem from './OncoreFlexMenuMobileItem';
import OncoreFlexMenuMobileLogout from './OncoreFlexMenuMobileLogout';
import { setMenuOpen } from 'src/store/appSlice';

const backEffect = keyframes`
  0%: {
    left: -100vw,
    right: 100vw
  },
  100%: {
    left: 0vw,
    right: 0vw
  }
`;
const subToChildEffect = keyframes`
  0%: {
    left: 100vw,
    right: -100vw
  },
  100%: {
    left: 0vw,
    right: 0vw
  }
`;

const BackButton = styled(IconButton)(({ theme }) => ({
  root: {
    color: theme.palette.primary.contrastText
  }
}));

const TopPane = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexFlow: 'column',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
});

const SubPane = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexFlow: 'column',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
});

type RenderFunc = (animation: Keyframes | undefined) => React.ReactNode;

type State = {
  parents: RenderFunc[];
  current?: RenderFunc;
  child?: RenderFunc;
  currentAnimation?: Keyframes;
};

const MOBILE_ACTION_SUB = 'sub';
const MOBILE_ACTION_BACK = 'back';
const MOBILE_ACTION_RESET = 'reset';

type SubAction = {
  type: typeof MOBILE_ACTION_SUB;
  payload: RenderFunc;
};

type BackAction = {
  type: typeof MOBILE_ACTION_BACK;
};

type ResetAction = {
  type: typeof MOBILE_ACTION_RESET;
};

type StateAction = SubAction | BackAction | ResetAction;

export type Props = {
  items: TopMenuItems;
  children?: never;
};

type OncoreFlexReducer = Reducer<State, StateAction>;

const OncoreFlexMenuMobile: React.FC<Props> = ({ items }) => {
  const { isMenuOpen } = useSelector((s) => s.app);
  const appDispatch = useDispatch();

  const [state, dispatch] = useReducer<OncoreFlexReducer>((previous, action) => {
    switch (action.type) {
      case MOBILE_ACTION_SUB: {
        return {
          ...previous,
          parents: previous.current ? [...previous.parents, previous.current] : previous.parents,
          current: action.payload,
          child: undefined,
          currentAnimation: subToChildEffect,
        };
      }
      case MOBILE_ACTION_BACK: {
        return {
          ...previous,
          parents: previous.parents.slice(0, -1),
          current: previous.parents.slice(-1)[0],
          child: previous.current,
          currentAnimation: backEffect,
        };
      }
      case MOBILE_ACTION_RESET: {
        return {
          parents: []
        };
      }
      default: {
        return previous;
      }
    }
  }, {
    parents: []
  });

  useEffect(() => {
    if (isMenuOpen) {
      dispatch({
        type: MOBILE_ACTION_RESET
      });
    }
  }, [isMenuOpen, dispatch]);

  const goSub = (item: MenuItem & MenuItemChildren) => {
    dispatch({
      type: MOBILE_ACTION_SUB,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      payload: getSubRenderFunc(item, item.children)
    });
  };

  const goBack = () => {
    dispatch({
      type: MOBILE_ACTION_BACK
    });
  };

  const backButton = (
    <BackButton onClick={goBack}>
      <ArrowBack />
    </BackButton>
  );

  const renderItem = (item: TopMenuItem | SubMenuItem | DivMenuItem): React.ReactNode => (
    <React.Fragment key={item.id}>
      {isTopMenuItem(item) && <OncoreFlexMenuMobileItem item={item} goSub={goSub} />}
      {isSubMenuItem(item) && <OncoreFlexMenuMobileItem item={item} goSub={goSub} />}
      {isDivMenuItem(item) && <OncoreFlexMenuDivider />}
    </React.Fragment>
  );

  const getTopRenderFunc = (): RenderFunc => ((animation) => (
    <TopPane key="root" sx={{ animation: `${ animation } .2s forwards` }}>
      {items.map(renderItem)}
      <OncoreFlexMenuMobileLogout key="logout" />
      <OncoreFlexMenuFill key="fill" />
    </TopPane>
  ));

  const getSubRenderFunc = (root: MenuItem, children: SubMenuItems): RenderFunc => ((animation) => (
    <SubPane key={root.id} sx={{ animation: `${ animation } .2s forwards` }}>
      <OncoreFlexMenuMobileItem
        item={{
          id: 'back',
          type: MENU_ITEM_TYPE_TOP,
          icon: () => (backButton),
          title: root.title,
          onClick: goBack
        }}
        goSub={() => {}}
      />
      {children.map(renderItem)}
      <OncoreFlexMenuFill />
    </SubPane>
  ));

  const current = state.current ?? getTopRenderFunc();
  const {
    currentAnimation,
  } = state;

  return (
    <Modal
      sx={{
        top: '56px !important',
      }}
      open={isMenuOpen}
      onClose={() => appDispatch(setMenuOpen(false))}
      hideBackdrop
    >
      <>
        {current && current(currentAnimation)}
      </>
    </Modal>
  );
};

export default OncoreFlexMenuMobile;
